import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic01 from '../assets/images/instructions-tree-fruit.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Moonlight</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>The Perfect Summertime Snack</h2>
                    </header>
                    <p>Moonlight tree fruits are juicy, sweet and bursting with flavor.  We offer everything from super sweet white flesh peaches and nectarines to tropical flavored pluots.</p>
                    <p>Bring a rainbow of color, aroma and taste to your kitchen with Moonlight tree fruit, available May through September.</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic01} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>LET’S MAKE IT RIPE!</h3>
                            </header>
                            <p>MOONLIGHT BRAND IS CALIFORNIA’S FINEST TREE FRUIT. HERE’S HOW TO ENSURE IT’S PERFECTLY RIPE:</p>
                            <ol>
                                <li>
                                    Remove any plastic packaging and place the fruit in a small paper bag.
                                </li>
                                <li>
                                    Close the bag loosely and leave it at room temperature, away from heat or sunlight. This speeds up the natural ripening process.
                                </li>
                                <li>
                                    Check for ripeness by placing fruit in the palm of your hand and gently squeezing. If it gives to light pressure and smells sweet and delicious, it’s ready to eat!
                                </li>
                                <li>
                                    When fruit has reached the ripeness you prefer, remove it from the bag and dig in! Or store in the refrigerator for up to a week.
                                </li>
                            </ol>
                            <ul className="actions">
                                <li><Link to="/#tree-fruit" className="button">Back</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing